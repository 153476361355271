// branding configuration
$publication: 'birminghampost';
$logo-dimensions: 600, 160;
$primary-color: #231F20;
$header-additional: #C7E2F6;

$brand-color: hsl(345, 6%,  13%);
$highlight: hsl(206, 100%, 71%);
$hint: hsl(205, 71%,  95%);
$lowlight: $brand-color;

// overrides
@mixin overrides() {
  .social-sites {
    @include social-icons(true);

    .icon {
      @include icons-background($header-additional, $primary-color);
    }

  }
}
